<template>
    <div id="Sections">

        

        <HeaderSec />
      
   
        <div v-if="historyOption =='Default'">
            <Default/>

        </div>
       
        <div v-else-if="historyOption =='Dashboard'">
                 <Default/>
        </div> 

         <div v-else-if="historyOption =='Servicios'">
                 <Servicios/>
        </div> 
        
        <div v-else-if="historyOption =='MiPerfil'">
                 <MiPerfil/>
        </div> 
         <div v-else-if="historyOption =='Sucursales'">
                 <Sucursales/>
        </div>  
           <div v-else-if="historyOption =='PDFcreate'">
            <PDFcreate />
        </div>
       
       
       
    
        
        <div v-else>
            <div class="proximamente">
                 <p>PRÓXIMAMENTE</p>
            </div>       
        </div>

       
    </div>
</template>
<script>

import {mapActions} from 'vuex';
import HeaderSec from '../components/HeaderSecCli.vue';
import Default  from '../views/Default/DefaulCli.vue'
import MiPerfil from '../views/clientes/miPerfil.vue'
import Servicios from '../views/clientes/serviciosCli.vue'
import Sucursales from '../views/clientes/sucursales.vue'
import PDFcreate from './PDFcreate.vue'

export default {
  name: 'Sections',
  components:{
      HeaderSec,
      Default,
      MiPerfil,
      Servicios,
      PDFcreate,
      Sucursales
    
  },
 
   data(){
       return{
           search_value:'',
           status:'',
           message:'',
           option:'Default',
       }
   },
    computed:{
        historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
    },
   created(){
        let option = localStorage.getItem('nav')
        if(option != undefined && option != null && option != ''){
            this.option = option
            this.setHistoryOptionMain(option)
        }
      
   },
   methods:{
       ...mapActions('main', ['setHistoryOptionMain']),
        ...mapActions('main', ['getHistoryOption']),
   }

   
    
 
}
</script>
<style scoped>
   
   #Sections{
    background: var(--color-5);
     min-height: 59.25VW;
     width: 78.17708333333333VW;
   }

   .proximamente p{
       color: var(--color-1);
       font-weight: 700;
       font-size: 5vw;
       margin-top: 11.5vw;
       margin-left: 15vw;
   }


  



</style>
