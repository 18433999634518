<template >
    <div id="MiPerfil">
       
        <div class="form-content">
            
                    <div class="text_option txt1">
                           
                           <!-- <h3>Información del Cliente</h3> -->
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="date_alt" class="date_alt">Fecha de recepción</label>
                            <input  v-model="data.date_alt" type="date" id="date_alt" name="date_alt"  disabled> 
                        </div>
                        <div>
                            <label class="hr" for="hr">Hora de recepción</label>
                            <input  v-model="data.hr" type="time"  id="hr" name="hr"  disabled  />
                        </div>
                    </div>
                    <div class="grup-form">
                        <div>
                            <label for="name" class="name">Nombre del cliente</label>
                            <input  v-model="data.name" type="text" id="name" name="name"    disabled class="in2">
                        </div>
                    </div>

                    <div class="grup-form">
                        <div>
                            <label for="address" class="address">Dirección</label>
                            <input v-model="data.address" type="text" id="address" name="address"   disabled class="in3">
                        </div>
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="city" class="city">Ciudad</label>
                            <input v-model="data.city" type="text" id="city"  name="city"  disabled class="in1">
                        </div>
                         <div>
                            <label for="col" class="col">Colonia</label>
                            <input v-model="data.col" type="text" id="col"  name="col"  disabled class="in1">
                        </div>
                       
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="phone" class="phone">Teléfono</label>
                            <input v-model="data.phone" type="text" id="phone" name="phone"  minlength="10" maxlength="10" size="10" pattern="\d*" disabled class="in1">
                        </div>
                         <div>
                            <label for="cp" class="cp">Código Postal</label>
                            <input v-model="data.cp" type="text" id="cp" name="cp"  pattern="\d*" disabled class="in1">
                        </div>
                    </div>

                     <div class="grup-form">
                       <div>
                            <label for="email" class="email">E-mail</label>
                            <input v-model="data.email" type="email" id="email" name="email" disabled class="in3">
                        </div>
                    </div>

                  


                    <div class="text_option txt2">
                            <h3>Datos Fiscales</h3>
                    </div>

                        <div class="grup-form">
                        <div>
                            <label for="td_rs" class="td_rs">Razón Social</label>
                            <input  v-model="data.td_rs" type="text" id="td_rs" name="td_rs"   disabled class="in4">
                        </div>
                    </div>

                        <div class="grup-form dflx">
                        <div>
                            <label for="td_rfc" class="td_rfc">RFC</label>
                            <input  v-model="data.td_rfc" type="text" id="td_rfc" name="td_rfc"    disabled class="in1">
                        </div> 
                        <div>
                            <label for="td_email" class="td_email">E-mail</label>
                            <input v-model="data.td_email" type="td_email" id="td_email" name="td_email"   disabled class="in1">
                        </div>
                    </div>

                        <div class="grup-form ">
                        <div>
                            <label for="td_phone" class="td_phone">Teléfono</label>
                            <input v-model="data.td_phone" type="text" id="td_phone" name="td_phone"  minlength="10" maxlength="10" size="10" pattern="\d*" disabled class="in1">
                        </div>
                        
                        </div>

                     <!--  <div class="dflx">
                            <button @click="wait()" class="btnRgr">Regresar</button>
                           
                        </div> -->
                        

                      
           

            
            

        </div>
           
    </div>
</template>
<script>

import {mapActions} from 'vuex';
import dayjs from 'dayjs'

export default {
    name:'MiPerfil',
    data(){
         return{
           status:'',
           message:'',
           name:"" ,
           address:"",
           city:"",
           col:"",
           cp:"",
           phone:"",
           email:"",
           pass:"",
           passc:"",
           date_alt:"",
           hr:"",
           td_rs:"",
           td_rfc:"",
           td_email:"",
           td_phone:"",


           //MODAL

            viewModal:false,



        }
    },
     async created (){
      let response = await  this.getIdentity()
     
       let data = {
           "id": response.result.sub,
           "option": 'client_b'
       }
       this.getInfoByIdCli(data)
   },
    computed:{
        identity() {
            return this.$store.getters['admin/getIdentity'];
        },
        data() {
           let data = this.$store.getters["clientes/data"];
            let day = dayjs(data.date_alt).format('YYYY-MM-DD')
            data.date_alt = day
             return data
        },
    },
    methods:{
         ...mapActions('clientes', ['getInfoByIdCli']),
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        

         getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },
   
    
        back:function(){
            setTimeout(() => this.$router.go(), 200);
        },

        convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },
    


   }
}
</script>
<style scoped>
 #MiPerfil{
        margin-top: 5.288020833333333VW;
        margin-left: 9.427083333333334VW;
    }

  
    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }


    .form-content{
        margin-left: 0.9375VW ;
    }

  button.alta{
      width: 7.916666666666666VW;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #00A175;
      margin-left: 0.625VW;
      margin-top: 2.7083333333333335VW;
  }
  button.alta span{
      font-weight: 600 !important;
  }

  label{
      color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
     
  }

  input{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
  }

  input.in1{
      width: 12.812499999999998VW;
  }

  input.in5{
      width: 9.812499999999998VW;
  }



    input.in2{
      width: 28.489583333333336VW;
  }

    input.in3{
      width: 31.927083333333332VW;
  }

    input.in4{
      width: 30.677083333333332VW;
  }

    label.name{
        width: 6.822916666666666VW; 
        margin-right: 0.7291666666666666VW;
    }

    label.address{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }
    
    label.city{
        width: 2.3958333333333335VW; 
        margin-right: 1.7187500000000002VW;
    }

     label.cp{
        width: 4.6875VW; 
        margin-right: 0.3645833333333333VW;
        margin-left: 1.25VW;
    }
    label.col{
       width: 2.3875VW;
        margin-right: .9VW;
        margin-left: 3VW;
    }

     label.email{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }

    label.phone{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }

    label.pass{
        width: 4.1VW; 
    }
    label.passc{
        width: 7.575VW; 
        margin-left: 1.7vw;
    }

    label.hr{
       width: 6.25VW; 
       margin-right: 1.40625VW;
       margin-left: 2.34375VW;
    }
    label.date_alt{
        width: 6.5625VW;
        margin-right: 1.09375VW;
    }
    label.td_rs{
        width: 4.270833333333333VW;
        margin-right: 1.09375VW;
    }
    label.td_rfc{
        width: 1.3020833333333335VW;
        margin-right: 2.8125VW;
    }
    label.td_email{
        width: 2.1354166666666665VW;
        margin-left: 3.5937499999999996VW;
        margin-right: 0.5729166666666666VW;
    }
    label.td_phone{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }

    .txt2{
        margin-top: 2.083333333333333VW;
    }

    .txt1{
        margin-bottom: 1.6145833333333335VW;
    }



    .grup-form{
        margin-bottom: 0.6770833333333334VW;
    }


  








</style>