<template>
   

    <div v-if="lab =='laason'" id="Dashboard">
        <SideMenu /> 
    
        <div class="content_sections">
            <Sections :option="'Default'" /> 
        </div>
       
      
    </div>
    <div v-else id="Dashboard">
        <SideMenu2 /> 
    
        <div class="content_sections">
            <Sections2 :option="'Default'" /> 
        </div>
    </div>
</template>
<script>


import SideMenu from '../components/SideMenuCli.vue'
import Sections from '../components/SectionsCli.vue'
import SideMenu2 from '../components/barragan/SideMenuCli.vue'
import Sections2 from '../components/barragan/SectionsCli.vue'
import {mapActions} from 'vuex';
export default {
  name: 'Dashboard',
  components:{
    SideMenu,
    Sections,
    SideMenu2,
    Sections2,
  },
  created(){
    let laboratorio = localStorage.getItem('laboratorio')
    console.log(laboratorio)
    if(laboratorio =='laason' || laboratorio =='barragan') {
      this.setlab(laboratorio)
    }
  },
  computed:{
    lab() {
      return this.$store.getters['main/getlab'];
    },
  },
  methods:{
      ...mapActions('main', ['setlab']),
  }
}
</script>
<style scoped>
   
   #Dashboard{
     display: flex;
   }

   .content_sections{
     background: var(--color-5);
     min-height: 59.25VW;
     width: 78.17708333333333VW;
     
   }
   img .imgprox{
    margin-left: 50%;
   }
</style>
