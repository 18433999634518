<template>
    <div id="SideMenu">
      <div class="logo_sm">
           <img  src="../assets/logo3.png" alt="Logo Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.">
      </div>
      <div class="header_menu">
          <h3> Hola, <span>{{this.identity.name   }} </span> </h3>
      </div>
      <div class="content_menu">
          <ul  class="">
               <li class="li_das"  :class="{active_das : navOption == 'Dashboard' }" @click="getOption('Dashboard')">    
                 <span>Dashboard</span>
               </li>
               
               
               

               <li class="li_sol" :class="{active_sol : navOption == 'Servicios' }" @click="getOption('Servicios'),setHistoryOptionSrv('Default')">  
                <span>Resultados</span>
               </li>
               
                

                <li class="li_usu" :class="{active_usu : navOption == 'MiPerfil' }"  @click="getOption('MiPerfil')">  
                
                  <span>Mi Perfil</span>
               </li>

                <li class="li_emp" :class="{active_emp : navOption == 'Sucursales' }" @click="getOption('Sucursales')">  
               
                 <span>Sucursales</span>
                
               </li>
         </ul>
      </div>
    </div>
</template>
<script>

import {mapActions} from 'vuex';
export default {
  name: 'SideMenu',
 data() {
        return {
            element: 0,
            navOption:'Default'  
        }
},
created(){
    this.getIdentity()
    let option = localStorage.getItem('nav')
    if(option != undefined || option != null || option != ''){
        this.navOption = option
    }
},
  computed:{
    identity() {
      return this.$store.getters['admin/getIdentity'];
    }
  },
  methods:{
      ...mapActions('main', ['setHistoryOptionMain']),
      ...mapActions('solserv', ['setHistoryOptionSrv']),
      getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },
      getOption: function(option){
         let role = this.identity.role;
        let type = this.identity.type;

          this.navOption = option
          localStorage.setItem('nav',option)
          this.setHistoryOptionMain(option)
            
      },
   
        
  },
 filters:{
     split: (value) => {
            if (!value) return '';
            value = value.toString();

            let splitStr = value.toLowerCase().split(' ');
            let len = splitStr.length
            let i;

            if(len == 1){
              return  splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);     
            }

            for ( i = 0; i < len; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            let nameCap = splitStr.join(' '); 
            let newName = nameCap.split(' ').slice(0, -1).join(' ')//cantidad de palabras quitadas.

            return newName;

        },
 }

}
</script>
<style scoped>
   #SideMenu{
      min-height: 59.25VW;
      width: 21.822916666666668VW;
     
   }

   .logo_sm{
       height: 19.166666666666668VW;
   }

   .logo_sm img{
    width: 17.708333333333336VW;
    height: 12.720833333333333VW;
    margin-left: 1.1979166666666667VW;
    margin-top: 3.5937499999999996VW;
   }


   .header_menu{
       background: var(--color-3);
      /* height: 3.802083333333333VW; */
       width: 21.822916666666668VW;
       border-radius: 0vw 2vw 2vw 0vw;
   }.header_menu h3{
       
       color:var(--color-5);
       font-size: 1.1458333333333333VW;
       padding-left: 4.322916666666667VW;
       padding-top: 1vw;
       margin-top: 0vw;
       margin-bottom: 0vw ;
       font-style: normal;
       font-weight: 300 !important;
       width: 21vw;
        padding-right: 0.5vw;
        padding-bottom: 1vw;
   }.header_menu h3 span{
      font-weight: 700;
   }

    .content_menu{
        margin-top: 2.864583333333333VW;
    }.content_menu ul{
       list-style: none;
       padding-left: 0vw;
   }.content_menu li{
       font-weight: 400;
       color: var(--color-2);
       font-size: 0.9375VW;
       width: 17.65625VW;
       height: 3.802083333333333VW;
       margin-bottom:1.7708333333333333VW ;
       padding-top: 1vw;
       padding-left: 8.697916666666666VW;
   }.content_menu li:hover, li.active_das, li.active_emp, li.active_usu, li.active_pru , li.active_prm , li.active_res, li.active_sol{
        color: var(--color-5);
   }.content_menu li span{
        width: 17.65625VW;
       height: 3.802083333333333VW;
   }
   
   
  
   .li_das{
       background-image: url('../assets/Dashboard.png');
        background-position: 30% 50%;
        background-size: 14%;
        background-repeat: no-repeat;
   }.li_das:hover , .active_das{
        background-image: url('../assets/Dashboard_hover.png');
        background-size: 100%;
   }

   .li_usu{
       background-image: url('../assets/Usuarios.png');
         background-position: 30% 50%;
        background-size: 14%;
        background-repeat: no-repeat;
   }.li_usu:hover , .active_usu{
        background-image: url('../assets/Usuarios_hover.png');
        background-size: 100%;
  }

   .li_res{
       background-image: url('../assets/Resultados.png');
        background-position: 30% 50%;
        background-size: 11%;
        background-repeat: no-repeat;
   }.li_res:hover , .active_res {
        background-image: url('../assets/Resultados_hover.png');
        background-size: 100%;
  }

   .li_sol{
       background-image: url('../assets/solicitudes_ico.png');
        background-position: 30% 50%;
        background-size: 11%;
        background-repeat: no-repeat;
   }.li_sol:hover , .active_sol {
        background-image: url('../assets/Solicitudes_Over.png');
        background-size: 100%;
  }


   .li_pru{
       background-image: url('../assets/Pruebas.png');
        background-position: 30% 50%;
        background-size: 13%;
        background-repeat: no-repeat;
   }.li_pru:hover , .active_pru{
        background-image: url('../assets/Pruebas_hover.png');
         background-size: 100%;
   }

   .li_prm{
       background-image: url('../assets/parametros_ico.png');
        background-position: 30% 50%;
        background-size: 13%;
        background-repeat: no-repeat;
   }.li_prm:hover , .active_prm{
        background-image: url('../assets/Parametros_Over.png');
         background-size: 100%;
   }

   .li_emp{
       background-image: url('../assets/Empresas.png');
         background-position: 30% 50%;
        background-size: 14%;
        background-repeat: no-repeat;
   }.li_emp:hover , .active_emp{
        background-image: url('../assets/Empresas_hover.png');
        background-size: 100%;
  }




</style>
