<template >
    <div id="MiPerfil">
       
        <p class="client_name2">{{data.name}}</p>
        <div class="info_cliente divtop dflx">
                   <p class="client_name">LISTA SUCURSALES </p>
                 

                       <div  class=" mlnb" id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev2" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages"> {{currentPage2}} - {{totalPages2}}</p>
                        <button @click="next2"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
                   </div>
                
                   
              </div>

        <div class="listsuc" >
                      <div v-if="data.sucursales != 'No se encontraron coincidencias.' && data.sucursales != 'error'">
                    <div v-for="(suc, key) in filteredItems2" :key="key">
                   <hr class="hrinf">
                    <div class="dflx">
                         <div class="info_cliente">
                   

                    <div class="dflx">
                   <p class="pdir">Nombre</p>
                    <p class="inp i3">{{suc.name}}</p>  
                  
                </div>

                <div class="dflx">
                    <p class="pdir">Direccion</p>
                    <p class="inp i3">{{suc.address}}</p>  
                    <p class="pciu">Ciudad</p>
                    <p class="inp i2">{{suc.city}}</p>  
                </div>

                <div class="dflx">
                    <p class="pcol">Colonia</p>
                    <p class="inp i2">{{suc.col}}</p>  
                    <p class="pcp">C.P.</p>
                    <p class="inp i2">{{suc.cp}}</p>  
                    <p class="ptel">Teléfono</p>
                    <p class="inp i2">{{suc.phone}}</p>  
                </div>
                
                </div>
                <div class="itoptions" >
                           
                        </div>
                    </div>
                </div>
                </div>
                <div v-else>
                       <hr class="hrinf">
                       <p class="ssuc">Sin sucursales registradas...</p>
                </div>

                 <hr class="hrinf">
               </div>
           
    </div>
</template>
<script>

import {mapActions} from 'vuex';
import dayjs from 'dayjs'

export default {
    name:'MiPerfil',
    data(){
         return{
           status:'',
           message:'',
          

            currentPage2: 1,
           itemsPerPage2:   4,

            viewModal:false,



        }
    },
     async created (){
      let response = await  this.getIdentity()
     
       let data = {
           "id": response.result.sub,
           "option": 'client'
       }
       this.getInfoByIdCli(data)
   },
    computed:{
        identity() {
            return this.$store.getters['admin/getIdentity'];
        },
        data() {
           let data = this.$store.getters["clientes/data"];
            let day = dayjs(data.date_alt).format('YYYY-MM-DD')
            data.date_alt = day
             return data
        },

         allItems2 () {
             
            let cliente = this.$store.getters["clientes/data"];
            let data = cliente.sucursales
            if(data != 'No se encontraron coincidencias.'){
                return Object.keys(data).map(pid => data[pid])
            }else{
                return 'sin datos'
            }
            
        },
        filteredItems2 () {
             if(this.allItems2 != 'sin datos'){
                  return  this.allItems2.slice((this.currentPage2 - 1) * this.itemsPerPage2, this.currentPage2 * this.itemsPerPage2 )
             }else{
                 return []
             }
            
        },
        totalPages2 () {

              if(this.allItems2 != 'sin datos'){
                  let total = Math.ceil(this.allItems2.length / this.itemsPerPage2)
                        if(total < this.currentPage2){
                        this.currentPage2 =1
                        }
                return total
             }else{
                 return 0
             }
          
       },
    },
    methods:{
         ...mapActions('clientes', ['getInfoByIdCli']),
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        

         getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },
   
    
        back:function(){
            setTimeout(() => this.$router.go(), 200);
        },

        convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },

         next2 () {
          
          if ( this.currentPage2 < this.totalPages2) {
            this.currentPage2++
          }
        },
        prev2 () {
          if (this.currentPage2 > 1) {
            this.currentPage2--
          }
        },
    


   }
}
</script>
<style scoped>
 #MiPerfil{
        margin-top: 5.288020833333333VW;
        margin-left: 9.427083333333334VW;
    }

  
    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }

  .listsuc{
    margin-bottom: 5vw;
}



.mlnb{
    margin-left: 35.3vw  !important;
}




.divtop{
    margin-top: 1vw;
}


    p.ssuc{
         width: 53.8VW; 
        height: 2.2916666666666665VW;
        text-align: center;
        padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
        font-size: 0.7375VW;
    }


  hr.hrinf{
    width: 57.969vw; 
    border: 0.5px solid #D3D3D3;
    margin-left: -1vw;
    margin-bottom: 2vw;
    margin-top: 1vw;
  }


   .info_cliente p{
        font-size: 0.677vw;
        padding-top: 0.148vw ;
        color: #868686;
    }

    p.client_name{
        font-size: 1.615vw;
        font-weight: 300;
        padding-top: 0vw;
    }
      p.client_name2{
        font-size: 1.615vw;
        font-weight:700;
        margin-bottom: 0vw;
         color: #868686;
    }

   p.inp{
        border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
       font-weight: 700;
       height: 1.354vw;
       padding-left: 0.573vw;
   }
   p.i2{
       width: 12.813vw;
   }
   p.i1{
       width: 11.094vw;
   }

    p.i3{
        width: 31.927vw;
    }

   
    p.pdir{
        width: 3.229vw;
        margin-right: 0.885vw;
    }

    p.pcol{
        width: 2.604vw;
        margin-right: 1.510vw;
    }

    p.pcp {
        margin-left: 3.958vw;
        margin-right:1.042vw ;
        width: 1.302vw;
    }

     p.pemail{
        margin-left:2.865vw;
        margin-right:1.719vw ;
        width: 2.135vw;
    }

     p.pciu{
        margin-left:2.865vw;
        margin-right:1.458vw ;
        width: 2.396vw;
    }
     p.ptel{
        margin-left:2.865vw;
        margin-right:0.833vw;
        width: 3.021vw;
    }


   


    .itoptions{
         width: 5.15625VW;
      
      display: flex;
       padding-top: 0.26875VW;
        padding-left: 0.78125VW;
        border: 1px solid white;
    }
     


    

  








</style>